<template>
    <div>
        <div class="page-title">服装试色</div>

        <color-plane type="cloth" @planeChanged="planeChanged">

            <div class="cloth-list flex flex-left-center">

                <template v-for="(item,ik) in list ">

                    <div :key="ik" class="cloth-item">

                        <div class="color-item">
                            <div :style="{backgroundColor:item.color}"></div>
                        </div>
                        <div class="cloth-name">{{ item.name }}</div>
                        <div class="cloth-color">{{ item.color.toUpperCase() }}</div>

                        <div v-if="planeId > 0" class="cloth-remove" @click="deleteLipstick(item)"></div>
                    </div>

                </template>

                <div v-if="planeId > 0" class="cloth-item" @click="newColor">

                    <div class="color-item"></div>
                    <div class="cloth-name">自定义</div>
                    <div class="cloth-color add-icon">添加颜色</div>

                </div>

            </div>

        </color-plane>

        <at-modal v-model="showLipstickModal" title="添加颜色" @ok="syncColor">
            <div class="at-input-group block">
                <div class="label">
                    <span>请输入色号名称</span>
                    <small>（非必填）</small>
                </div>
                <div>
                    <at-input v-model="params.name" :max-length="7" class="full-with"/>
                </div>
            </div>
            <div class="at-input-group block">
                <div class="label">
                    <span>请输入RGB色号值</span>
                </div>
                <div class="label">
                    <at-input :max-length="7" :value=" params.color " class="full-with" @input="colorChanged"/>
                </div>
                <div>
                    <div class="color-container">
                        <input :value=" params.color " type="color" class="color-input" @input="colorChanged"/>
                        <div class="color-tips">点击选色</div>
                    </div>
                </div>
            </div>
            <div class="clearfix"></div>
        </at-modal>

    </div>
</template>

<script>
import ColorPlane from "@/views/AST/ColorTools/Com/ColorPlane.vue";
import AtModal from "@/components/AtModal.vue";
import AtInput from "@/components/AtInput.vue";
import api from "@/repo/api";

export default {
    name: "Cloth",
    components: {AtInput, AtModal, ColorPlane},
    data() {
        return {
            planeId: 0,
            showLipstickModal: false,
            params: {
                color: '',
                name: '',
            },
            list: [],
        }
    },
    methods: {
        newColor() {
            this.params = {
                color: "#DDDDDD",
                name: '',
            }
            this.showLipstickModal = true
        },
        syncColor() {
            this.$loading.show()

            this.params.plane_id = this.planeId

            if (!this.params.name) {
                this.params.name = this.params.color.toUpperCase()
            }

            api.post('/ast-color-plane/sync-cloth', this.params, data => {

                this.$loading.hide()

                this.$message.data(data)

                if (data.code === 0) {
                    this.showLipstickModal = false
                    this.getCloth()
                }

            })
        },
        planeChanged(planeId) {
            this.planeId = planeId
            this.getCloth()
        },
        deleteLipstick(item) {
            this.$confirm({
                title: '确定要删除这个颜色吗？',
                onOk: () => {
                    api.post('/ast-color-plane/del-cloth', {
                        id: item.id,
                        plane_id: this.planeId
                    }, data => {

                        this.$message.data(data)

                        if (data.code !== 0) return;

                        this.getCloth()
                    })
                }
            })
        },
        getCloth() {

            api.get('/ast-color-plane/cloths', {plane_id: this.planeId}, data => {

                if (data.code !== 0) return;

                this.list = data.data;

            })
        },
        colorChanged(e) {
            let color = e.currentTarget.value
            this.params.color = color
        },
    }
}
</script>

<style scoped lang="less">
.color-tips {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #0000006e;
    color: white;
    height: 40px;
    text-align: center;
    padding-top: 7px;
    pointer-events: none;
}

.cloth-item {
    width: 96px;
    height: 148px;
    border-radius: 4px;
    border: 1px solid #EEEEEE;
    padding: 16px 10px;
    cursor: pointer;

    margin-right: 12px;
    margin-bottom: 12px;
    position: relative;
}

.cloth-list {
    flex-wrap: wrap;
}

.color-item {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background-image: url("/assets/icons/color-add-icon.png");
    background-size: cover;
    margin-left: 6px;
    overflow: hidden;


    div {
        width: 100%;
        height: 100%;
    }
}

.cloth-name {
    font-size: 12px;
    color: #333333;
    line-height: 16px;
    text-align: center;
    margin-top: 8px;
}

.cloth-color {
    margin-top: 4px;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    border: 1px solid #EEEEEE;
}

.add-icon {
    height: 24px;
    background: rgba(251, 176, 59, 0.1);
    border-radius: 0;
    border: 1px solid #FBB03B;
    color: #FBB03B;
}


.color-container {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    margin: 0 auto;

    input {
        width: 160px;
        height: 160px;
        left: -20px;
        top: -20px;
        position: absolute;
    }
}

.cloth-remove {
    width: 12px;
    height: 12px;
    background-image: url("/assets/icons/close-btn-black.png");
    background-size: cover;
    position: absolute;
    right: 4px;
    top: 4px;
    opacity: 0;
}

.cloth-item:hover .cloth-remove {
    opacity: 1;
}

</style>